import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import ApprofondimentiPage from "../components/slugPages/approfondimentiPage"

const ApprofondimentiPageTemplate = ({ data, location }) => (
  <Layout
    pageMeta={{
      title: `${data.strapiApprofondimenti.titolo}`,
      keywords: [`${data.strapiApprofondimenti.titolo}`],
      description: `${data.strapiApprofondimenti.titolo}`,
      link: `/meditazioni-e-approfondimenti/${data.strapiApprofondimenti.slug}`,
    }}
    location={location}
  >
    <ApprofondimentiPage data={data} location={location} />
  </Layout>
)

export default ApprofondimentiPageTemplate

export const postQuery = graphql`
  query ApprofondimentiPageQuery($slug: String!) {
    allStrapiApprofondimenti(sort: { fields: updatedAt, order: DESC }) {
      edges {
        node {
          strapiId
          autore {
            nome
          }
          slug
          titolo
          categoria
        }
      }
    }
    strapiApprofondimenti(slug: { eq: $slug }) {
      strapiId
      slug
      titolo
      categoria
      autore {
        nome
      }
      multimedia {
        id
        testo
        video_link
        file {
          ext
          publicURL
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
