import React from "react"
import Markdown from "react-markdown"
import ReactAudioPlayer from "react-audio-player"
import Img from "gatsby-image"
import Pdf from "../multimedia/pdf"
import EmbedVideo from "../multimedia/embedVideo"
import ApprofondimentiCard from "../cards/approfondimentiCard"
import FadeInAnimation from "../animations/fade"
import { LinkRenderer, HeadingRenderer } from "../../utilities/markdownUtils"
import approfondimentiPageStyles from "../../styles/slugPages/approfondimenti-page.module.scss"
import markdownStyles from "../../styles/common/markdown.module.scss"

const ApprofondimentiPage = ({ data, location }) => {
  const approfondimento = data.strapiApprofondimenti
  const approfondimenti =
    data.allStrapiApprofondimenti.edges
      .filter((item) => item.node.strapiId !== approfondimento.strapiId)
      .slice(0, 3) || []
  const isPageValid = approfondimento.titolo && approfondimento.multimedia
  if (isPageValid) {
    return (
      <div
        key={approfondimento.strapiId}
        className={approfondimentiPageStyles.pageWrapper}
      >
        <h2 className={approfondimentiPageStyles.title}>
          <FadeInAnimation direction="right">
            {approfondimento.titolo}{" "}
          </FadeInAnimation>
        </h2>
        <article className={approfondimentiPageStyles.selected}>
          <FadeInAnimation direction="up">
            {approfondimento.multimedia.map((item) => (
              <>
                {item.file && item.file.ext === ".pdf" && (
                  <Pdf url={item.file.publicURL} key={item.file.publicURL} />
                )}
                {item.file && item.file.ext === ".mp3" && (
                  <>
                    <ReactAudioPlayer
                      src={item.file.publicURL}
                      controls
                      key={item.file.publicURL}
                      // preload="none"
                    />
                    <br />
                  </>
                )}
                {item.testo && (
                  <Markdown
                    key={item.strapiId}
                    className={markdownStyles.testo}
                    renderers={{
                      heading: HeadingRenderer,
                      link: LinkRenderer,
                    }}
                    source={item.testo}
                  />
                )}
                {item.video_link && <EmbedVideo url={item.video_link} />}
              </>
            ))}
          </FadeInAnimation>
        </article>
        <article className={approfondimentiPageStyles.foto}>
          <FadeInAnimation direction="left">
            {approfondimenti.map((approf) => (
              <ApprofondimentiCard
                key={approf.node.strapiId}
                approfondimento={approf}
                location={location}
              />
            ))}
            <div className={approfondimentiPageStyles.fotoGrid}>
              {approfondimento.multimedia.map((item) => (
                <>
                  {item.file &&
                    item.file.ext !== ".mp3" &&
                    item.file.ext !== ".pdf" && (
                      <Img
                        key={item.file.publicURL}
                        className={markdownStyles.image}
                        fluid={item.file.childImageSharp.fluid}
                        alt={approfondimento.titolo}
                      />
                    )}
                </>
              ))}{" "}
            </div>
          </FadeInAnimation>
        </article>
      </div>
    )
  }
  return null
}

export default ApprofondimentiPage
