/* eslint-disable no-shadow */
import React, { useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import pdfStyles from "../../styles/multimedia/pdf.module.scss"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const Pdf = ({ url }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setPageNumber(1)
  }

  const onItemClick = ({ pageNumber: itemPageNumber }) => {
    setPageNumber(itemPageNumber)
  }

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  const previousPage = () => {
    changePage(-1)
  }

  const nextPage = () => {
    changePage(1)
  }
  return (
    <>
      <p className={pdfStyles.counter}>
        Pagina {pageNumber || (numPages ? 1 : "--")} di {numPages || "--"}
      </p>
      <div className={pdfStyles.pdf}>
        <button
          className={pdfStyles.btnPrevious}
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          ◄
        </button>

        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<div className={pdfStyles.loading}>Un attimo...</div>}
          onItemClick={onItemClick}
        >
          <Page
            pageNumber={pageNumber}
            loading={<div className={pdfStyles.loadingPage} />}
          />
        </Document>

        <button
          className={pdfStyles.btnNext}
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          ►
        </button>
      </div>
    </>
  )
}

export default Pdf
