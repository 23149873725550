import React from "react"
import ReactPlayer from "react-player"
import embedVideoStyles from "../../styles/multimedia/embed-video.module.scss"

function EmbedVideo({ url }) {
  return (
    <div className={embedVideoStyles.playerWrapper}>
      <ReactPlayer url={url} className={embedVideoStyles.reactPlayer} />
    </div>
  )
}

export default EmbedVideo
